export const environment = {
  _ssp: 'M#0AygZi36C9*EcPxNATUsB^V9WlZmUQJ^FrrJ$tUvvBQAnnftWS@DKfhmcrQ!*z',
  api: {
    "ull_": "https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/",
    "uss_": 'U2FsdGVkX194OQ6Fhb7bFqabdNcyPXViWJLrJ8mBs0k=',
    "pss_": 'U2FsdGVkX19QcQIhfqibUohxb0wgk2k3cztQWMyRfPI=',
  },
  production: false,
  gtag_mode: true,
  contacto : {
    wsp: "+56950075507",
    telefonocall: "+56223850180",
    texto : "Hola! Necesito ayuda"
  },
  // codigo para desarrollo
  gtagcode: 'GTM-WTG2GL9',
  apiurl: 'https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/',
  flag_fala: true
};